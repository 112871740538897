<template>
  <div class="">
      <headers></headers>
      <div class="layout-main-content" style="min-height: calc(100vh - 80px);">
        <router-view></router-view>
      </div>
      <service-box></service-box>
      <bottom></bottom>
  </div>
</template>
  
<script>
import bottom from '../../components/bottom'
import headers from '../../components/headers'
import serviceBox from "../../components/serviceBox";
export default {
  data() {
    return {

    };
  },
  components:{
    bottom,headers,serviceBox
  },
  computed: {},
  mounted() {
    
  },
  methods: {
    
  },
};
</script>
<style lang="scss" scoped>
.layout-main-content{
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  z-index: 1;
  background: #f5f5f5;
}

</style>
