<template>
    <div class="service-box">
        <div class="phone">
            <img :src="img1" alt="">
        </div>
        
        <div class="say-box">
            <ol>
                <li v-for="(item,index) in texts" :key="index + 'w'">
                    <img :src="require('../assets/serve-box/sanjiao.png')" alt="">
                    {{item.title}} <a :href="item.url" target="_blank">{{item.text}}</a>
                </li>
            </ol>
        </div>
        <ul>
            <li>
                <div><a  @click="gotoConsult" target="_blank">
                    <div>
                        <img :src="require('../assets/serve-box/icon-1.png')" alt="">
                        <img :src="require('../assets/serve-box/icon-1-on.png')" alt="">
                    </div>
                    <p>在线咨询</p>
                </a></div>
            </li>
            <li>
                <div>
                    <div>
                        <img :src="require('../assets/serve-box/icon-2.png')" alt="">
                        <img :src="require('../assets/serve-box/icon-2-on.png')" alt="">
                    </div>
                    <p>客服热线</p>
                </div>
                <div>
                    <div><img :src="require('../assets/serve-box/icon-2.png')" alt=""></div>
                    <p>{{datas.consultTel}}</p>
                </div>
            </li>
            <li>
                <div>
                    <div>
                        <img :src="require('../assets/serve-box/icon-3.png')" alt="">
                        <img :src="require('../assets/serve-box/icon-3-on.png')" alt="">
                    </div>
                    <p>关注我们</p>
                </div>
                <div class="lf-right">
                    <div class="wright">
                        <div style="margin-top: 15px">用微信扫描下方</div>
                        <div>二维码进入小程序</div>
                        <div class="xiaochengxu"><el-image :src="datas.xcxewm"></el-image></div>
                        <div>用微信扫描下方</div>
                        <div>二维码进入公众号</div>
                        <div class="gzh"><el-image :src="datas.gzhewm"></el-image></div>
                    </div>
                </div> 
            </li>
            <li @click="scrollToTop">
                <div>
                    <div>
                        <img :src="require('../assets/serve-box/icon-4.png')" alt="">
                        <img :src="require('../assets/serve-box/icon-4-on.png')" alt="">
                    </div>
                    <p>回到顶部</p>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import {indexpublic} from "../request/moudle";

export default{
    data() {
        return{
            img1: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/serve-box/out_quality65_loop0_frametime40.webp",
            // img: [
            //     require('../assets/serve-box/1.png'),
            //     require('../assets/serve-box/2.png'),
            //     require('../assets/serve-box/3.png'),
            //     require('../assets/serve-box/4.png'),
            // ],
            imgUrl: require("../assets/serve-box/1.png"),
            texts: [
                {title: 'Hi~欢迎访问 BHXQ产业发展服务平台！', text: '', url: ''},
            //    {title: '抢先申领工业互联网标识！', text: '申领试用', url: '/trial'},  
            //     {title: '申领成功即送平台2个月免费使用名额，先到先得！', text: '', url: ''},  
            ],
            timer: null,
            abc: null,
            bcd: null,
            timerNum: 5,
            datas:'',
        }
    },
    created() {
        this.loadconfig();
    },
    methods: {
        scrollToTop() {
            $('body,html').animate({scrollTop: 0}, 1000)
        },

        start() {
            var _this = this;
            var n = 0;
            var a = -1;

            this.bcd = setInterval(content, 30000)
            function content() {
                a++;
                $('.say-box ol li').eq(a).css({'z-index': 9, 'display': 'block'})
                $('.say-box ol li').eq(a).siblings().css({'z-index': 1, 'display': 'none'})
                if(a > 1){
                    a = -1;
                    this.bcd
                }
            }
        },
        loadconfig(){
            this.$api.index.indexpublic().then((res)=>{
                this.datas=res.data;
            })
        },
        gotoConsult(){
            window.open(this.datas.consultUrl)
        }
    },
    mounted() {
        this.start()
    },
    beforeDestroy() {
        clearInterval(this.timer)
        this.timer = null
    }
}
</script>

<style scoped lang="scss">
.service-box{
    width: 110px;
    height: 416px;
    position: fixed;
    top: 62%;
    right: 10px;
    margin-top: -260px;
    z-index: 999;
    border-radius: 10px;
    .phone{
        width: 110px;
        height: 110px;
        position: relative;
        >img{
            width: 130px;
            height: 130px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    .say-box{
        width: 198px;
        height: 66px;
        position: absolute;
        bottom: 416px;
        right: 0px;
        ol{
            width: 198px;
            height: 66px;
            position: absolute;
            bottom: 0;
            right: 0;
            li{
                width: 168px;
                height: auto;
                padding: 10px 15px;
                position: absolute;
                bottom: 0;
                right: 0;
                font-size: 14px;
                line-height: 22px;
                border-radius: 3px;
                box-shadow: 0 0 12px rgba(0,0,0,0.15);
                display: none;
                background: #fff;
                img{
                    position: absolute;
                    bottom: -12px;
                    right: 46px;
                    width:17px;
                    height:12px;
                }
                a{
                    color: #3460f2;
                }
            }
        }
    }
    >img{
        width: 110px;
        height: 130px;
        margin-bottom: 5px;
    }
    ul{
        width: 70px;
        margin: 0 auto;
        box-shadow: 0 0 15px rgba(8,46,174,0.15);
        background: #fff;
        border-radius: 4px;
        li{
            width: 70px;
            height: 70px;
            text-align: center;
            // background-color: #ffffff;
            padding: 0 5px;
            box-sizing: border-box;
            position: relative;
            cursor: pointer;
            >div:nth-child(1){
                padding: 11px 0;
                border-bottom: 1px solid #bcbcbc;
                div{
                    width: 100%;
                    height: 21px;
                    position: relative;
                    img{
                        width: 22px;
                        height: 21px;
                        position: absolute;
                        top: 0;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                    img:nth-child(2){
                        display: none;
                    }
                }
                p{
                    font-size: 12px;
                    margin-top: 10px;
                    color: #666666;
                }
            }
            // &:hover {
            //     background: #40C6FF;
            // }
        }

        li:nth-child(2){
            >div:nth-child(2){
                width: 165px;
                height: 39px;
                background: url('../assets/serve-box/pic_1.png') no-repeat;
                padding: 10px;
                box-sizing: border-box;
                position: absolute;
                top: 50%;
                right: 80px;
                transform: translateY(-50%);
                display: none;
                div{
                    margin-right: 10px;
                    float: left;
                }
                p{
                    line-height: 21px;
                    float: left;
                }
            }
        }
        li:nth-child(3){
            >div:nth-child(2){
                position: absolute;
                bottom: 10px;
                right: 80px;
                transform: translateY(-50%);
                display: none;
                img{
                    width: 108px;
                    height: 100px;
                }
            }
        }
        li:last-child{
            >div:nth-child(1){
                padding-top: 15px;
                border: 0 none;
                div{
                    height: 12px;
                    img{
                        width: 17px;
                        height: 12px;
                    }
                }
            }
        }
        li:hover{
            >div:nth-child(1){
                p{
                    color: #3460f2;
                }
                div{
                    img:nth-child(1){
                        display: none;
                    }
                    img:nth-child(2){
                        display: block;
                    }
                }
            }
            >div:nth-child(2){
                display: block;
            }
        }
    }
    .lf-right{
    width: 250px;
    position: relative;
    color: #333333;
    text-align: center;
    .wright{
        width: 130px;
        height: 300px;
        position: fixed;
        right: 8px;
        top: -80px;
        background: rgba(255,255,255,0.6);
        border-radius: 4px;
        text-align: center;
        font-size: 12px;
        z-index: 99999999;
    }
    .xiaochengxu{
        width: 85px;
        height: 85px;
        margin: 10px auto;
    }
    .gzh{
        width: 85px;
        height: 85px;
        margin: 0 auto;
        margin-top: 10px;
    }
    }
}
</style>
